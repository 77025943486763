import React from 'react'
import Logo from './../images/logo_stoffwindelberatung.svg'

export default () => (
  <Logo
    className="logo"
    width="270px"
    height="94px"
    alt="Stoffwindelberatung Julia Rose"
  />
)
