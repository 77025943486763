import 'typeface-fira-sans'
import { Link } from 'gatsby'

import React from 'react'
import './../static/iwata-style.css'
import Logo from './../components/logo'
import Helmet from 'react-helmet'

class DefaultLayout extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Stoffwindelberatung in Bielefeld, Detmold und Paderborn – ich erkläre euch vor Ort alles über Stoffwindeln, Wickeln mit Stoff und natürlichen Materialien."
          />
        </Helmet>
        {/* <div className="header section">
          <div className="section-inner">
            <h2 className="blog-title">
              <Link to="/">
                <Logo />
              </Link>
            </h2>
            <ul className="main-menu">
              <li>foo</li>
            </ul>
            <div className="clear" />
          </div>
        </div> */}
        <div className="section content">
          <div className="section-inner">
            {this.props.children}
            <br />
            <a href="https://www.julia-wickelt.de/">
              <Logo />
            </a>
          </div>
        </div>
        <div className="section footer bg-accent">
          <div className="section-inner">
            <p className="copyright">
              <span>
                <a
                  href="https://www.julia-wickelt.de//impressum/"
                  target="_blank"
                >
                  Impressum
                </a>{' '}
                <span>&nbsp;</span>
              </span>
              <span>
                <a
                  href="https://www.julia-wickelt.de//datenschutz/"
                  target="_blank"
                >
                  Datenschutz
                </a>{' '}
                <span>&nbsp;</span>
              </span>
            </p>
            <p className="contact">
              <span>Kontakt:</span> &nbsp;
              <a href="https://www.julia-wickelt.de/">Julia Rose</a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="tel://01796861549">0179 68 61 549</a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="mailto://info@julia-wickelt.de">info@julia-wickelt.de</a>
            </p>

            {/* <a href="#" title="Go back to the top" className="to-the-top">
              <Arrow className="to-the-top-arrow" width="27px" />
            </a> */}
          </div>
        </div>
      </>
    )
  }
}

// DefaultLayout.propTypes = {
//   location: PropTypes.object.isRequired,
// }

export default DefaultLayout
