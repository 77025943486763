import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import PostIcons from '../components/PostIcons'
import Layout from '../layouts'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

class PageTemplate extends Component {
  render() {
    const { data } = this.props
    console.log('data: ', data)
    const homePage = data.allMarkdownRemark.edges[0].node
    const siteMetaData = data.site.siteMetadata
    console.log('homePage: ', homePage)

    return (
      <Layout>
        <Helmet
          // title={`${siteMetaData.name}${
          //   homePage.title ? ` – ${homePage.title}` : ''
          // }`}
          title={siteMetaData.title}
          bodyAttributes={{
            class: 'page-template-default page ',
          }}
        />
        {siteMetaData.description && (
          <Helmet>
            <meta name="description" content={siteMetaData.description} />
          </Helmet>
        )}
        <div className="post single page type-page status-publish hentry">
          <div className="post-header">
            <Img fixed={data.baby.childImageSharp.fixed} />
            <h1
              dangerouslySetInnerHTML={{ __html: homePage.frontmatter.title }}
              className="post-title"
            />
          </div>
          <div className="featured-media">
            <Img fluid={data.image.childImageSharp.fluid} />
          </div>
          {/* <PostIcons node={currentPage} css={{ marginBottom: rhythm(1 / 2) }} /> */}
          <div
            dangerouslySetInnerHTML={{ __html: homePage.html }}
            className="post-content"
          />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query {
    image: file(absolutePath: { regex: "/schnecke.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    baby: file(absolutePath: { regex: "/logo_baby.png/" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
